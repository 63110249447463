import {pluralDeclension} from './../utils/plural-declension';

function checkIe() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    return true;
  } else {
    return false;
  }
}

class FondCards {
  constructor(dataUrl, clicksApiUrls) {
    this.localStorage = window.localStorage;
    this.data = [];
    this.dataChangeCallbacks = [];
    this.loadingData = true;
    this.clicksData = {
      total_counter: 0,
      funds: [],
    };
    this.totalClicksChangeCallbacks = [];
    this.counterGetError = false;
    this.clicksApiUrls = clicksApiUrls;

    this.element = null;
    this.cardElements = [];

    this.render();
    this.getData(dataUrl);
    this.getClicksData(clicksApiUrls.base + clicksApiUrls.get);
    // this.incrementCounter(2);
  }

  setData(data) {
    this.data = data;
    this.executeDataChangeCallbacks();
  }

  addDataChangeCallback(callback) {
    this.dataChangeCallbacks.push(callback);
  }

  executeDataChangeCallbacks() {
    this.dataChangeCallbacks.forEach((callback) => {
      callback();
    });
  }

  getData(dataUrl) {
    this.element.innerHTML = '<li class="fond-cards__loader"></li>';
    fetch(dataUrl)
        .then((res) => res.ok ? res : Promise.reject({status: res.status, message: res.statusText}))
        .then((res) => res.json())
        .then((data) => {
          const sortedData = this.sortData(data);
          this.setData(sortedData);
          this.loadingData = false;
          this.renderCards();
        })
        .catch((err) => this.renderError(err));
  }

  addTotalCilcksChangeCallback(callback) {
    this.totalClicksChangeCallbacks.push(callback);
  }

  executeTotalClicksChangeCallbacks(err) {
    this.totalClicksChangeCallbacks.forEach((callback) => {
      callback(this.clicksData.total_counter, err);
    });
  }

  getClicksData(clicksGetUrl) {
    fetch(clicksGetUrl)
        .then((res) => res.ok ? res : Promise.reject({status: res.status, message: res.statusText}))
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            this.counterGetError = false;
            this.clicksData = data.data;
            this.executeTotalClicksChangeCallbacks();
            this.renderAllCardCounters();
          } else {
            Promise.reject({status: data.status});
          }
        })
        .catch((err) => this.renderAllCounterError(
            'При загрузке с сервера данных о количестве раз оказанной поддержки произошла ошибка.',
            err
        ));
  }

  incrementCounter(id) {
    const lastClickedMsString = this.localStorage.getItem(`pfizerFondClick_${id}`);
    const lastClickedMs = parseInt(lastClickedMsString, 10);

    if (!lastClickedMsString || (Date.now() - lastClickedMs) >= 240000) {
      fetch(this.clicksApiUrls.base + this.clicksApiUrls.post + id, {method: 'POST'})
          .then((res) => res.ok ? res : Promise.reject({status: res.status, message: res.statusText}))
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              let clicksDataFund = this.clicksData.funds.find((fund) => fund.id === data.data.id);
              if (clicksDataFund) {
                clicksDataFund = data.data;
              } else {
                this.clicksData.funds.push(data.data);
              }
              this.clicksData.total_counter = this.clicksData.total_counter + 1;

              this.renderCardCounter(data.data.fund_id, data.data.cnt);
              this.executeTotalClicksChangeCallbacks();

              this.localStorage.setItem(`pfizerFondClick_${id}`, Date.now());
            } else {
              Promise.reject({status: data.status});
            }
          })
          .catch((err) => this.renderCardCounterError(id,
              'При загрузке на сервер данных о клике на кнопку "Помочь" произошла ошибка.',
              err));
    }
  }

  sortData(data) {
    const arr = [...data];

    return arr.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }

  getTable() {
    const preloaderHtml = '<li class="fond-cards__loader"></li>';
    const cardsHtml = this.data.map((card) => this.getCard(card)).join('\n');
    return `
      <ul class="fond-cards__list">
        ${this.loadingData ? preloaderHtml : cardsHtml}
      </ul>`;
  }

  getCard(cardData) {
    const cardColorClass = 'card-fond--' + cardData.color;
    const ieClass = checkIe() ? 'card-fond--ie' : '';
    const logoUrl = cardData.logo || 'img/content/card-logo-placeholder.svg';
    const logoExt = logoUrl.slice(-4);
    const logoModClass = logoExt === '.svg' ? 'card-fond__logo--svg' : '';
    const category = cardData.category || 'всем';
    const categoryJS = cardData.categoryJS || 'all';
    const photoHtml = cardData.photo ? `<img class="card-fond__photo" src="${cardData.photo}" alt="Фотография от фонда">` : '';
    const counterFundData = this.clicksData.funds.find((fund) => fund.fund_id + '' === cardData.id + '');
    const paidTimes = counterFundData ? counterFundData.cnt : null;
    let paidTimesText = paidTimes ? `поддержали ${this.paidTimesDecline(paidTimes)}` : 'поддержали 0 раз';
    if (this.counterGetError) {
      paidTimesText = '';
    }

    return `
      <li class="fond-cards__card card-fond ${cardColorClass} ${ieClass}" data-category="${categoryJS}" data-id="${cardData.id}">
        <div class="card-fond__wrapper">
          <div class="card-fond__front">
            <div class="card-fond__logo-container">
              <img class="card-fond__logo ${logoModClass}" src="${logoUrl}" alt="Лого фонда ${cardData.name}">
            </div>
            <p class="card-fond__category">${category}</p>
            <p class="card-fond__name">${cardData.name}</p>
            <p class="card-fond__front-description">${cardData.frontDescription}</p>
          </div>
          <div class="card-fond__back">
            <div class="card-fond__photo-container">
              ${photoHtml}
            </div>
            <a class="card-fond__link" href="${cardData.link}" target="_blank" rel="noopener noreferrer">
              <span class="card-fond__link-text">${cardData.linkText}</span>
              <svg class="card-fond__link-icon" width=16 height=16 aria-hidden="true">
                <use xlink:href="#icon-external-link"></use>
              </svg>
            </a>
            <p class="card-fond__back-description">${cardData.backDescription}</p>
            <a class="card-fond__pay-button" data-id="${cardData.id}" href="${cardData.payLink}" target="_blank" rel="noopener noreferrer">помочь</a>
            <p class="card-fond__paid-times">${paidTimesText}</p>
          </div>
        </div>
      </li>`;
  }

  paidTimesDecline(number) {
    return pluralDeclension(number, 'раз', 'раза', 'раз');
  }

  setEventListeners() {
    this.cardElements.forEach((element) => {
      element.querySelector('.card-fond__pay-button').onclick = (evt) => {
        this.incrementCounter(evt.target.dataset.id);
      };
    });
  }

  render() {
    const wrapper = document.createElement('div');

    wrapper.innerHTML = this.getTable();

    const element = wrapper.firstElementChild;

    this.element = element;

    this.setEventListeners();
  }

  renderCards() {
    this.element.innerHTML = this.data.map((card) => this.getCard(card)).join('\n');
    this.cardElements = this.element.querySelectorAll('.fond-cards__card');
    this.setEventListeners();
  }

  renderCardCounter(id, count, err) {
    const cardElement = this.element.querySelector(`.fond-cards__card[data-id="${id}"]`);
    if (cardElement) {
      const cardCounterElement = cardElement.querySelector('.card-fond__paid-times');
      const paidTimesText = err ? '' : `поддержали ${this.paidTimesDecline(count)}`;

      cardCounterElement.innerText = paidTimesText;
    }
  }

  renderAllCardCounters() {
    this.clicksData.funds.forEach((fundData) => {
      this.renderCardCounter(fundData.fund_id, fundData.cnt);
    });
  }

  renderError(err) {
    const errStatus = err.status ? `Статус: "${err.status}". ` : '';
    const errMessage = err.message ? `Сообщение: "${err.message}"` : '';

    this.element.innerHTML = '<li class="fond-cards__error-message">При загрузке данных карточек фондов произошла ошибка. Пожалуйста попробуйте позже.</li>';

    console.log(`При загрузке данных карточек фондов произошла ошибка. ${errStatus}${errMessage}.`);
  }

  renderCardCounterError(id, info, err) {
    this.renderCardCounter(id, 0, true);

    const errStatus = err.status ? `Статус: "${err.status}". ` : '';
    const errMessage = err.message ? `Сообщение: "${err.message}"` : '';

    console.log(info + ' ' + errStatus + errMessage);
  }

  renderAllCounterError(info, err) {
    this.counterGetError = true;

    this.executeTotalClicksChangeCallbacks(true);

    this.cardElements.forEach((element) => {
      element.querySelector('.card-fond__paid-times').innerText = '';
    });

    const errStatus = err.status ? `Статус: "${err.status}". ` : '';
    const errMessage = err.message ? `Сообщение: "${err.message}"` : '';

    console.log(info + ' ' + errStatus + errMessage);
  }

  existsOfType(type) {
    return this.data.findIndex((card) => card.categoryJS.toLowerCase() === type.toLowerCase()) !== -1;
  }

  filterShownCards(sortCategory) {
    this.cardElements.forEach((card) => {
      const lowerSortCategory = sortCategory.toLowerCase();
      const cardCategory = card.dataset.category.toLowerCase();
      if (lowerSortCategory === 'all' || cardCategory === 'all' || lowerSortCategory === cardCategory) {
        card.classList.remove('fond-cards__card--hidden');
      } else {
        card.classList.add('fond-cards__card--hidden');
      }
    });
  }
}

export default FondCards;
