import FondCards from './fond-cards-component';
import {pluralDeclension} from './../utils/plural-declension';

const fondCardsSectionElement = document.querySelector('.fond-cards');
let fondCards;

if (fondCardsSectionElement) {
  const dataUrl = fondCardsSectionElement.dataset.cardsUrl;
  const clicksApiUrls = {
    base: fondCardsSectionElement.dataset.clickBase,
    get: fondCardsSectionElement.dataset.clickGet,
    post: fondCardsSectionElement.dataset.clickPost,
  };
  fondCards = new FondCards(dataUrl, clicksApiUrls);

  fondCardsSectionElement.innerHTML = '';
  fondCardsSectionElement.appendChild(fondCards.element);


  const filterInputElements = document.querySelectorAll('.filter input[type="radio"]');

  const setFilterButtons = () => {
    filterInputElements.forEach((element) => {
      if (element.dataset.category.toLowerCase() === 'all' || fondCards.existsOfType(element.dataset.category)) {
        element.disabled = false;
      } else {
        element.disabled = true;
      }
    });
  };

  setFilterButtons();
  fondCards.addDataChangeCallback(setFilterButtons);

  filterInputElements.forEach((element) => {
    element.onchange = (evt) => {
      fondCards.filterShownCards(evt.target.dataset.category);
    };
  });

  // const introTotalCounterElement = document.querySelector('.intro__counter-people-amount');
  const introCounter = document.querySelector('.intro__counter');

  const setIntroTotalCounter = (count, err) => {
    if (introCounter) {
      introCounter.innerText = err ? '' : `Уже поддержали ${pluralDeclension(count, 'человек', 'человека', 'человек')}`;
    }
  };

  fondCards.addTotalCilcksChangeCallback(setIntroTotalCounter);
  fondCards.executeTotalClicksChangeCallbacks();
}
