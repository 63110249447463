import {ieFix} from './utils/ie-fix';
import {iosVhFix} from './utils/ios-vh-fix';

// import {initModals} from './modules/init-modals';
import './modules/help-now-scroll';
import './modules/fond-cards';

// Utils
// ---------------------------------

ieFix();
iosVhFix();

// Modules
// ---------------------------------

// initModals();

// fetch('./data/fond-cards.json')
//     .then((res) => res.json())
//     .then((data) => console.log(data));
