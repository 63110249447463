const introHelpElement = document.querySelector('.intro__help-button');
const helpNowSectionElement = document.querySelector('.help-now');

if (introHelpElement && helpNowSectionElement) {
  introHelpElement.onclick = function () {
    helpNowSectionElement.scrollIntoView({
      behavior: 'smooth',
    });
  };
}
