function pluralDeclension(number, formOne, formTwo, formFive) {
  let result;
  const lastTwoDigits = number % 100;
  const lastDigit = number % 10;

  if (lastTwoDigits >= 10 && lastTwoDigits <= 20 || lastDigit >= 5 && lastDigit <= 9 || lastDigit === 0) {
    result = `${number} ${formFive}`;
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    result = `${number} ${formTwo}`;
  } else if (lastDigit === 1) {
    result = `${number} ${formOne}`;
  }

  return result;
}

export {pluralDeclension};
